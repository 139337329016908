import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
export const data = graphql`
  query {
    file(relativePath: { eq: "cpj-reads.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Img fluid={props.data.file.childImageSharp.fluid} alt="Christi Porter Johnson reads to a Co-op student" style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p>{`Dear Families,`}</p>
    <p>{`Thank you for visiting our website. The Watchung Cooperative Preschool is the oldest preschool in Montclair. It was founded by Betty Bailey and a group of parents in the 1960’s. From the school’s inception we have fostered a partnership of parents, teachers, and children. The beginning of preschool marks a milestone in both the parents and child’s life. The Co-op affords children the opportunity to venture out of the home into a safe, loving, and nurturing environment. We offer children a play based curriculum in a nurturing and meaningful setting. Rather than the teachers following a set yearly curriculum, the teachers work to create lessons based on the children’s curiosities and interests. This type of inquiry allows for a rich level of learning and excitement within the classroom environment.`}</p>
    <p>{`As a cooperative school, we believe that all families should have an opportunity to partner with teachers and actively participate in their child’s first school experience. The transition from home to school is met with a compassionate nod from teachers at our school. At the Co-op the teachers and parents create a partnership where home and school work in unison.`}</p>
    <p>{`We hope you will consider the Watchung Co-op Preschool for your child’s first school experience. If you are interested in learning more about our school please call us to schedule a tour.`}</p>
    <p>{`Sincerely,`}</p>
    <p>{`Christi Porter-Johnson`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      