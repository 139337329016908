import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { MDXProvider } from "@mdx-js/react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const shortcodes = { Img, Link }

export default function SinglePageTemplate({ pageContext, children }) {
  return (
    <Layout>
      <Helmet>
        <title>{pageContext.frontmatter.title} | Watchung Co-op</title>
      </Helmet>
      <header
        css={{
          margin: "5rem 0 1.25rem",
          position: "relative",
          background: "no-repeat center center",
          backgroundSize: "cover",
          //marginTop: '3.1875rem',
          //marginBottom: '2.1875rem',
          "@media(min-width: 992px)": {
            marginTop: "8.125rem",
          },
        }}
      >
        <Container>
          <Row>
            <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
              <h1 css={(theme) => ({ color: theme.colors.pager })}>
                {pageContext.frontmatter.title}
              </h1>
              <hr
                css={(theme) => ({
                  boxShadow: "1px 1px 3px #000",
                  maxWidth: "6.25rem",
                  margin: "1rem auto",
                  borderWidth: ".25rem",
                  borderColor: "inherit",
                  borderRadius: ".1875rem",
                  fontSize: ".875em",
                  opacity: 0.25,
                  color: theme.colors.pager,
                })}
              />
            </Col>
          </Row>
        </Container>
      </header>
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
            <MDXProvider components={shortcodes}>
              {children}
            </MDXProvider>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}